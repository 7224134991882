import React from 'react';
import { IWidgetProduct } from 'graphql/products/productsInterfaces';
import { ProductImage } from 'ui/component/product/product-image';
import styles from './style.css';

interface IDefaultImageProps {
    product: IWidgetProduct;
}

export const DefaultImage: React.FC<IDefaultImageProps> = (
    {
        product,
    },
) => (
    <div className={styles.defaultImageWrapper}>
        <div className={styles.imageContainer}>
            <ProductImage product={product} />
        </div>
    </div>
);
