import React from 'react';
import { classes } from '@silkpwa/module/util/classes';
import { ISalesLayoutConfig } from 'ui/page/sales-pages/layouts/sales-layout/sales-layout-state';
import { ICustomerOrder, IOrderShipment } from 'graphql/customer/customerOrdersInterfaces';
import { Shipment } from './shipment';
import styles from '../style.css';

export interface IShipmentsParams {
    config: ISalesLayoutConfig;
    order: ICustomerOrder;
    shipments: IOrderShipment[];
}

export const Shipments: React.FC<IShipmentsParams> = (
    {
        config,
        order,
        shipments,
    },
) => {
    if (!shipments || !shipments.length) {
        return null;
    }

    return (
        <div className={classes(styles.orderEntityList)}>
            {shipments.map(
                (shipment: IOrderShipment) => (
                    <Shipment config={config} order={order} shipment={shipment} />
                ),
            )}
        </div>
    );
};
