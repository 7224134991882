import React, { useEffect, useState } from 'react';
import { classes } from '@silkpwa/module/util/classes';
import { AccessibleButton } from '@silkpwa/module/react-component/accessible-button';
import { ISalesLayoutConfig } from 'ui/page/sales-pages/layouts/sales-layout/sales-layout-state';
import { ArchiveOrderAction } from 'ui/component/sales-components/archive-order-action';
import { ICustomerOrder } from 'graphql/customer/customerOrdersInterfaces';
import { OrderHeader } from './header';
import { OrderItems } from './items';
import styles from './style.css';

export interface IOrderParams {
    config: ISalesLayoutConfig;
    order: ICustomerOrder;
    className?: string;
    showOrderHeader?: boolean;
    showItemsAlways?: boolean;
    orderDetails?: boolean;
}

export const Order: React.FC<IOrderParams> = (
    {
        config,
        order,
        className,
        showOrderHeader = true,
        showItemsAlways = false,
        orderDetails = false,
    },
) => {
    const [wrapperHasZIndex, setWrapperHasZIndex] = useState(false);
    const [isExpanded, setIsExpanded] = useState(true);
    const [isOrderArchived, setIsOrderArchived] = useState(false);

    const {
        number: orderNumber,
        is_archived: isOrderInArchive,
    } = order;

    const isArchivingAvailable = typeof isOrderInArchive === 'boolean';

    const toggleExpanded = (event?: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent): void => {
        if (event) event.preventDefault();
        const newIsExpanded = isOrderArchived ? false : !isExpanded;
        setIsExpanded(newIsExpanded);
    };

    useEffect(() => {
        if (isArchivingAvailable) {
            setIsOrderArchived(isOrderInArchive);
        }
    }, [isArchivingAvailable]);

    useEffect(() => {
        setIsExpanded(!isOrderArchived);
    }, [isOrderArchived]);

    if (!order || !order.id) {
        return null;
    }

    return (
        <div
            className={classes(styles.order, {
                [styles.hasZIndex]: wrapperHasZIndex,
            }, (className || ''))}
        >
            {showOrderHeader && (
                <AccessibleButton
                    tag="button"
                    className={classes({
                        [styles.orderHeaderWrapper]: true,
                        [styles.topBorderRadius]: true,
                        [styles.bottomBorderRadius]: !showItemsAlways && !isExpanded && (
                            !isArchivingAvailable || isOrderArchived
                        ),
                    })}
                    action={toggleExpanded}
                    aria-expanded={isExpanded}
                    tabIndex={0}
                >
                    <OrderHeader config={config} order={order} />
                </AccessibleButton>
            )}
            {(showItemsAlways || !isOrderArchived) && (
                <div
                    className={classes({
                        [styles.orderItemsWrapper]: true,
                        [styles.topBorderRadius]: !showOrderHeader,
                        [styles.bottomBorderRadius]: (showItemsAlways || isExpanded) && (
                            !isArchivingAvailable || isOrderArchived
                        ),
                        [styles.collapsed]: !showItemsAlways && !isExpanded,
                    })}
                >
                    {order.items && (
                        <OrderItems
                            config={config}
                            order={order}
                            setWrapperHasZIndex={setWrapperHasZIndex}
                            orderDetails={orderDetails}
                        />
                    )}
                </div>
            )}
            {isArchivingAvailable && (
                <div
                    className={classes({
                        [styles.archiveOrderWrapper]: true,
                        [styles.bottomBorderRadius]: true,
                        [styles.hasTopBorder]: showItemsAlways || isExpanded,
                    })}
                >
                    <ArchiveOrderAction
                        orderNumber={orderNumber}
                        isOrderArchived={isOrderArchived}
                        setIsOrderArchived={setIsOrderArchived}
                    />
                </div>
            )}
        </div>
    );
};
