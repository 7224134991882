import React, { createContext, useContext } from 'react';

interface IOrderDetailsLoaderContext {
    orderDetailsPageLoading: boolean;
    setOrderDetailsPageLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export const LoaderContext = createContext<IOrderDetailsLoaderContext | undefined>(undefined);

export const useOrderDetailsLoader = (
): IOrderDetailsLoaderContext => useContext(LoaderContext) as IOrderDetailsLoaderContext;
