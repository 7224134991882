import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { classes } from '@silkpwa/module/util/classes';
import { SET_ORDER_ARCHIVED } from 'graphql/sales/mutations/setOrderArchived';
import { Messages } from 'ui/component/custom/messages';
import { SalesActionButton } from '../sales-action-button';
import styles from './style.css';

export interface IArchiveOrderActionParams {
    orderNumber: string;
    isOrderArchived: boolean;
    setIsOrderArchived: (isOrderArchived: boolean) => void;
    className?: string;
    showMessages?: boolean;
}

export const ArchiveOrderAction: React.FC<IArchiveOrderActionParams> = (
    {
        orderNumber,
        isOrderArchived,
        setIsOrderArchived,
        className,
        showMessages = true,
    },
) => {
    const [isArchiving, setIsArchiving] = useState(false);
    const [archiveSuccessMessages, setArchiveSuccessMessages] = useState<string[]>([]);
    const [archiveErrorMessages, setArchiveErrorMessages] = useState<string[]>([]);
    const [archiveSuccess, setArchiveSuccess] = useState(false);
    const [archiveError, setArchiveError] = useState(false);

    const t = usePhraseTranslater();

    const [setOrderArchived] = useMutation(SET_ORDER_ARCHIVED, {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
        onError: ({ graphQLErrors }) => {
            const errorMessages: string[] = graphQLErrors.map((graphQLError) => {
                const { message } = graphQLError;
                return message;
            });
            setArchiveErrorMessages(errorMessages);
            setArchiveError(true);
        },
        onCompleted: (data): void => {
            const { setOrderArchived } = data;
            const { actualIsArchived } = setOrderArchived;
            setIsOrderArchived(actualIsArchived);

            const action = actualIsArchived ? t('archived') : t('unarchived');
            const successArchiveMessage = t('Order #%1 is successfully %2.', orderNumber, action);
            setArchiveSuccessMessages([successArchiveMessage]);
            setArchiveSuccess(true);
        },
    });

    const clearMessages = () => {
        setArchiveSuccess(false);
        setArchiveError(false);
    };

    const setOrderArchivedAsyncAction = async (isArchived: boolean): Promise<void> => {
        clearMessages();
        setIsArchiving(true);
        await setOrderArchived({
            variables: {
                orderNumber,
                isArchived,
            },
        });
    };

    const archiveAction = (event?: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent): void => {
        if (event) event.preventDefault();
        setOrderArchivedAsyncAction(true).finally(() => {
            setIsArchiving(false);
        });
    };

    const unArchiveAction = (event?: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent): void => {
        if (event) event.preventDefault();
        setOrderArchivedAsyncAction(false).finally(() => {
            setIsArchiving(false);
        });
    };

    return (
        <div className={classes(styles.archiveOrder, (className || ''))}>
            {isArchiving && (
                <div className={classes(styles.archiveRow, styles.archivingLabel)}>{t('Archiving the Order...')}</div>
            )}
            {!isArchiving && (
                <>
                    <div className={classes(styles.archiveRow, styles.archiveActionSection)}>
                        {isOrderArchived && (
                            <div className={styles.orderIsArchived}>
                                <span className={styles.archiveOrderLabel}>{t('Order Is Archived')}</span>
                            </div>
                        )}
                        <SalesActionButton
                            label={(!isOrderArchived ? t('Archive Order') : t('Unarchive Order'))}
                            className={styles.archiveOrderBtn}
                            action={(!isOrderArchived ? archiveAction : unArchiveAction)}
                        />
                    </div>
                    {showMessages && (
                        <Messages
                            mainWrapperClassName={classes(styles.archiveRow, styles.archiveOrderMessages)}
                            successMessages={archiveSuccessMessages}
                            showSuccessMessages={archiveSuccess}
                            setShowSuccessMessages={setArchiveSuccess}
                            hideSuccessMessagesDelay={5000}
                            errorMessages={archiveErrorMessages}
                            showErrorMessages={archiveError}
                            setShowErrorMessages={setArchiveError}
                            hideErrorMessagesDelay={5000}
                        />
                    )}
                </>
            )}
        </div>
    );
};
