import React from 'react';
import { IWidgetProduct } from 'graphql/products/productsInterfaces';
import { ProductLink } from '../product-link';
import styles from './style.css';

interface IProductImageProps {
    product: IWidgetProduct;
    image: JSX.Element;
}

export const ProductImage: React.FC<IProductImageProps> = (
    {
        product,
        image,
    },
) => (
    <ProductLink product={product} className={styles.productImageLink}>
        {image}
    </ProductLink>
);
