import { gql, TypedDocumentNode } from '@apollo/client';

interface ISetOrderArchivedInput {
    orderNumber: string;
    isArchived: boolean;
}

interface ISetOrderArchivedOutput {
    setOrderArchived: {
        actualIsArchived: boolean;
    };
}

export const SET_ORDER_ARCHIVED: TypedDocumentNode<ISetOrderArchivedOutput, ISetOrderArchivedInput> = gql`
    mutation SET_ORDER_ARCHIVED(
        $orderNumber: String!
        $isArchived: Boolean!
    ) {
        setOrderArchived(
            orderNumber: $orderNumber
            isArchived: $isArchived
        ) {
            actualIsArchived
        }
    }
`;
