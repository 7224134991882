import React from 'react';
import { classes } from '@silkpwa/module/util/classes';
import { IWidgetProduct } from 'graphql/products/productsInterfaces';
import styles from './style.css';

interface IProductLinkProps {
    product: IWidgetProduct;
    className?: string;
    children?: JSX.Element;
}

export const ProductLink: React.FC<IProductLinkProps> = (
    {
        product,
        className,
        children,
    },
) => (
    <a
        href={product.productUrl}
        className={classes(styles.productLink, (className || ''))}
        title={product.name}
    >
        {children && (children)}
    </a>
);
