import React from 'react';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { classes } from '@silkpwa/module/util/classes';
import {
    IDiscount,
    IEarnedReward,
    ISalesTotal,
} from 'graphql/customer/customerOrdersInterfaces';
import { SummaryLine } from 'ui/component/sales-components/summary-line';
import styles from './style.css';

interface ISalesSummaryProps {
    salesTotal: ISalesTotal;
    className?: string;
    lineClassName?: string;
    lineRowClassName?: string;
    showSeparators?: boolean;
}

export const SalesSummary: React.FC<ISalesSummaryProps> = (
    {
        salesTotal,
        className,
        lineClassName,
        lineRowClassName,
        showSeparators = false,
    },
) => {
    const t = usePhraseTranslater();

    const {
        subtotal,
        discounts,
        total_tax: totalTax,
        grand_total: grandTotal,
        shipping_handling: shippingHandling,
        total_rewards: totalRewards,
        total_applied_giftcards: appliedGiftCards,
        total_refunded_to_store_credit: refundedToStoreCredit,
    } = salesTotal;

    const { value: subtotalValue } = subtotal;
    const { value: taxValue } = totalTax;
    const { value: grandTotalValue } = grandTotal;
    const { amount_excluding_tax: shippingExclTax } = shippingHandling;
    const { value: shippingExclTaxValue } = shippingExclTax;
    const rewardsSpent = totalRewards ? totalRewards.spent : null;
    const rewardsEarned = totalRewards ? totalRewards.earned : null;

    interface IRewardPoint {
        reward: IDiscount|IEarnedReward;
        isDiscount: boolean;
    }

    const rewardPoints: IRewardPoint[] = [];
    if (rewardsSpent !== null) {
        rewardPoints.push({
            reward: rewardsSpent,
            isDiscount: true,
        });
    }

    if (rewardsEarned !== null) {
        rewardPoints.push({
            reward: rewardsEarned,
            isDiscount: false,
        });
    }

    const totalBeforeTaxValue = subtotalValue + shippingExclTaxValue;

    return (
        <div className={classes(styles.orderSummaryWrapper, (className || ''))}>
            <SummaryLine
                label={t('Item(s) Subtotal:')}
                amount={subtotalValue}
                lineClassName={classes(styles.subtotal, (lineClassName || ''))}
                lineRowClassName={classes(styles.lineRow, (lineRowClassName || ''))}
            />
            <SummaryLine
                label={t('Shipping & Handling:')}
                amount={shippingExclTaxValue}
                lineClassName={classes(styles.shipping, (lineClassName || ''))}
                lineRowClassName={classes(styles.lineRow, (lineRowClassName || ''))}
            />
            {showSeparators && (<div className={styles.separator}>-----</div>)}
            <SummaryLine
                label={t('Total before tax:')}
                amount={totalBeforeTaxValue}
                lineClassName={classes(styles.totalBeforeTax, (lineClassName || ''))}
                lineRowClassName={classes(styles.lineRow, (lineRowClassName || ''))}
            />
            <SummaryLine
                label={t('Estimated tax:')}
                amount={taxValue}
                lineClassName={classes(styles.tax, (lineClassName || ''))}
                lineRowClassName={classes(styles.lineRow, (lineRowClassName || ''))}
            />
            {discounts.map(
                (discount) => {
                    const { amount, label } = discount;
                    const { value } = amount;
                    return (
                        <SummaryLine
                            label={`${label}:`}
                            amount={value}
                            lineClassName={classes(styles.discount, (lineClassName || ''))}
                            lineRowClassName={classes(styles.lineRow, (lineRowClassName || ''))}
                            isDiscount
                        />
                    );
                },
            )}
            {rewardPoints.map(
                (rewardPoint: IRewardPoint) => {
                    const { reward, isDiscount } = rewardPoint;
                    const { amount, label } = reward;
                    const { value } = amount;
                    return (
                        <SummaryLine
                            label={`${label}:`}
                            amount={value}
                            lineClassName={classes(styles.discount, (lineClassName || ''))}
                            lineRowClassName={classes(styles.lineRow, (lineRowClassName || ''))}
                            isDiscount={isDiscount}
                        />
                    );
                },
            )}
            {appliedGiftCards.map(
                (appliedGiftCard) => {
                    const { amount, label } = appliedGiftCard;
                    const { value } = amount;
                    return (
                        <SummaryLine
                            label={`${label}:`}
                            amount={value}
                            lineClassName={classes(styles.discount, (lineClassName || ''))}
                            lineRowClassName={classes(styles.lineRow, (lineRowClassName || ''))}
                            isDiscount
                        />
                    );
                },
            )}
            {showSeparators && (<div className={styles.separator}>-----</div>)}
            <SummaryLine
                label={t('Grand Total:')}
                amount={grandTotalValue}
                lineClassName={classes(styles.grandTotal, (lineClassName || ''))}
                lineRowClassName={classes(styles.lineRow, (lineRowClassName || ''))}
            />
            {!!refundedToStoreCredit && [refundedToStoreCredit].map(
                (toStoreCredit) => {
                    const { amount, label } = toStoreCredit;
                    const { value } = amount;
                    return (
                        <SummaryLine
                            label={`${label}:`}
                            amount={value}
                            lineClassName={classes(styles.storeCredit, (lineClassName || ''))}
                            lineRowClassName={classes(styles.lineRow, (lineRowClassName || ''))}
                        />
                    );
                },
            )}
        </div>
    );
};
