import React from 'react';
import { classes } from '@silkpwa/module/util/classes';
import { ISalesLayoutConfig } from 'ui/page/sales-pages/layouts/sales-layout/sales-layout-state';
import { ICustomerOrder, IOrderCreditMemo } from 'graphql/customer/customerOrdersInterfaces';
import { Refund } from './refund';
import styles from '../style.css';

export interface IRefundsParams {
    config: ISalesLayoutConfig;
    order: ICustomerOrder;
    refunds: IOrderCreditMemo[];
}

export const Refunds: React.FC<IRefundsParams> = (
    {
        config,
        order,
        refunds,
    },
) => {
    if (!refunds || !refunds.length) {
        return null;
    }

    return (
        <div className={classes(styles.orderEntityList)}>
            {refunds.map(
                (refund: IOrderCreditMemo) => (
                    <Refund config={config} order={order} refund={refund} />
                ),
            )}
        </div>
    );
};
