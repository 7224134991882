import React from 'react';
import { ICustomerOrder } from 'graphql/customer/customerOrdersInterfaces';
import { ISalesLayoutConfig } from 'ui/page/sales-pages/layouts/sales-layout/sales-layout-state';
import { SalesDetailsLayout } from 'ui/component/sales-components/sales-details-layout';
import { Order } from '../order';
import styles from './style.css';

interface IOrderDetailsProps {
    config: ISalesLayoutConfig;
    order?: ICustomerOrder|undefined;
}

export const OrderDetails: React.FC<IOrderDetailsProps> = (
    {
        config,
        order,
    },
) => {
    if (!order) {
        return null;
    }

    return (
        <SalesDetailsLayout
            order={order}
            className={styles.orderDetailsWrapper}
        >
            <Order
                config={config}
                order={order}
                className={styles.orderWrapper}
                showOrderHeader={false}
                showItemsAlways
                orderDetails
            />
        </SalesDetailsLayout>
    );
};
