import React from 'react';
import { classes } from '@silkpwa/module/util/classes';
import { IOrderPaymentMethod } from 'graphql/customer/customerOrdersInterfaces';
import styles from './style.css';

export interface IPaymentMethodsParams {
    paymentMethods: IOrderPaymentMethod[];
    label?: string;
    showType?: boolean;
    className?: string;
    methodsClassName?: string;
    labelClassName?: string;
}

export const PaymentMethods: React.FC<IPaymentMethodsParams> = (
    {
        paymentMethods,
        label,
        showType = false,
        className,
        methodsClassName,
        labelClassName,
    },
) => {
    if (!paymentMethods || !paymentMethods.length) {
        return null;
    }

    return (
        <div className={classes(styles.paymentMethodsWrapper, (className || ''))}>
            {label && (
                <div className={classes(styles.paymentMethodsLabel, (labelClassName || ''))}>
                    {label}
                </div>
            )}
            <div className={classes(styles.paymentMethods, (methodsClassName || ''))}>
                {paymentMethods.map(
                    (paymentMethod) => {
                        const { name, type } = paymentMethod;
                        return (
                            <div className={classes(styles.method)}>
                                <div className={styles.methodName}>{name}</div>
                                {showType && (
                                    <div className={styles.methodType}>{`(${type})`}</div>
                                )}
                            </div>
                        );
                    },
                )}
            </div>
        </div>
    );
};
