import React, { useState } from 'react';
import { PageContent } from '../layouts/components/page-content';
import { InitializeOrderDetailsPage } from './order-details-state';
import { LoaderContext } from './order-details-loader';

interface IOrderDetailsPageContentProps {
    pageTitle?: string;
    children?: JSX.Element;
}

export const OrderDetailsPageContent: React.FC<IOrderDetailsPageContentProps> = (
    {
        pageTitle,
        children,
    },
) => {
    const [orderDetailsPageLoading, setOrderDetailsPageLoading] = useState(false);
    return (
        <LoaderContext.Provider value={{ orderDetailsPageLoading, setOrderDetailsPageLoading }}>
            <InitializeOrderDetailsPage>
                <PageContent
                    pageTitle={pageTitle}
                    pageLoading={orderDetailsPageLoading}
                >
                    <>{children}</>
                </PageContent>
            </InitializeOrderDetailsPage>
        </LoaderContext.Provider>
    );
};
