import React from 'react';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import {
    ICustomerOrder,
    IOrderAddress,
} from 'graphql/customer/customerOrdersInterfaces';
import { ISalesLayoutConfig } from 'ui/page/sales-pages/layouts/sales-layout/sales-layout-state';
import { Link } from 'ui/component/custom/link';
import { SummaryLine } from 'ui/component/sales-components/summary-line';
import { LinkPopup } from 'ui/component/custom/link-popup';
import { OrderAddress } from 'ui/component/sales-components/sales-details-layout/order-information/order-address';
import { HeaderInfo } from 'ui/component/sales-components/header-info';
import styles from './style.css';

export interface IOrderParams {
    config: ISalesLayoutConfig;
    order: ICustomerOrder;
}

export const OrderHeader: React.FC<IOrderParams> = (
    {
        order,
    },
) => {
    const t = usePhraseTranslater();

    const {
        number,
        order_date_formatted: date,
        order_urls: orderUrls,
        total,
        invoices,
        shipping_address: shippingAddress,
    } = order;
    const { grand_total: grandTotal } = total;
    const { value: grandTotalValue } = grandTotal;
    const {
        details_url: orderUrl,
        invoice_url: invoicesUrl,
    } = orderUrls;
    const hasInvoices = Boolean(invoices && invoices.length > 0);

    const getFullName = (order: ICustomerOrder): string => {
        const shippingAddress: IOrderAddress = order.shipping_address;
        const { firstname, middlename, lastname } = shippingAddress;
        const firstNamePart: string = firstname;
        const secondNamePart: string = middlename ? `${middlename} ${lastname}` : lastname;
        return `${firstNamePart} ${secondNamePart}`;
    };

    if (!order || !number) {
        return null;
    }

    return (
        <div className={styles.orderHeader}>
            <HeaderInfo title={t('Order Placed')}>
                {date}
            </HeaderInfo>
            <HeaderInfo title={t('Total')}>
                <SummaryLine
                    amount={grandTotalValue}
                    lineClassName={styles.headerText}
                />
            </HeaderInfo>
            <HeaderInfo title={t('Ship To')}>
                <LinkPopup
                    linkLabel={getFullName(order)}
                    wrapperClassName={styles.shipToLink}
                    linkClassName={styles.headerText}
                    linkLabelClassName={styles.shipToLabel}
                    contentClassName={styles.shipToPopupContent}
                    stopPropagation
                >
                    <OrderAddress
                        orderAddress={shippingAddress}
                        className={styles.shippingAddress}
                    />
                </LinkPopup>
            </HeaderInfo>
            <HeaderInfo title={t('Order #%1', number)} textClassName={styles.links}>
                <>
                    <Link
                        label={t('View Order Details')}
                        href={orderUrl}
                        opacityOnHover
                    />
                    {hasInvoices && (
                        <Link
                            label={(invoices.length > 1 ? t('View Invoices') : t('View Invoice'))}
                            href={invoicesUrl}
                            opacityOnHover
                        />
                    )}
                </>
            </HeaderInfo>
        </div>
    );
};
