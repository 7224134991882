import React from 'react';
import { classes } from '@silkpwa/module/util/classes';
import { IWidgetProduct } from 'graphql/products/productsInterfaces';
import { ProductLink } from '../product-link';
import styles from './style.css';

interface IProductNameProps {
    product: IWidgetProduct;
    className?: string;
}

export const ProductName: React.FC<IProductNameProps> = (
    {
        product,
        className,
    },
) => (
    <div className={classes(styles.productName, (className || ''))}>
        <ProductLink
            product={product}
            className={styles.productLink}
        >
            <span>{product.name}</span>
        </ProductLink>
    </div>
);
