import React, { useEffect } from 'react';
import { useOrderDetailsLoader } from './order-details-loader';

interface IInitializeOrderDetailsPageProps {
    children: React.ReactNode;
}

export const InitializeOrderDetailsPage: React.FC<IInitializeOrderDetailsPageProps> = (
    {
        children,
    },
) => {
    const { orderDetailsPageLoading, setOrderDetailsPageLoading } = useOrderDetailsLoader();

    useEffect(() => {
        if (!orderDetailsPageLoading) {
            setOrderDetailsPageLoading(true);
        }

        setOrderDetailsPageLoading(false);
    }, []);

    return (<>{children}</>);
};
