import React from 'react';
import { classes } from '@silkpwa/module/util/classes';
import { IWidgetProduct, IThumbImage } from 'graphql/products/productsInterfaces';
import { ProductImage } from 'ui/component/product/product-image';
import styles from './style.css';

interface IImagesProps {
    product: IWidgetProduct;
    images: IThumbImage[];
    isVertical?: boolean;
}

export const Images: React.FC<IImagesProps> = (
    {
        product,
        images,
        isVertical = false,
    },
) => (
    <div
        data-sku={product.sku}
        className={classes(styles.imagesWrapper, {
            [styles.vertical]: isVertical,
        })}
    >
        <ProductImage product={{ ...product, images }} />
    </div>
);
