import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { classes } from '@silkpwa/module/util/classes';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { connectCart } from '@silkpwa/module/react-component/connect-cart';
import { ISalesLayoutConfig } from 'ui/page/sales-pages/layouts/sales-layout/sales-layout-state';
import { IOrderItem, TypeSplitOrderItem } from 'graphql/customer/customerOrdersInterfaces';
import {
    ADD_PRODUCTS_TO_CART_MUTATION,
    IUserError,
    IAddToCartItem,
} from 'graphql/cart/cart';
import { SalesActionButton } from 'ui/component/sales-components/sales-action-button';
import { Messages } from 'ui/component/custom/messages';
import Popup from 'ui/component/popup/popup';
import styles from '../style.css';

export type TypeBtnMouseOrKeyboardClickEvent = React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent;
export type TypeBuyAgainOrderItem = IOrderItem|TypeSplitOrderItem;

interface IBuyAgainActionProps {
    config: ISalesLayoutConfig;
    orderItems: TypeBuyAgainOrderItem[];
    btnLabel?: string;
    wrapperClassName?: string;
    btnClassName?: string;
    showBtnImage?: boolean;
    messagesClassName?: string;
    showMessagesInPopup?: boolean;
}

interface IConnectCartProps {
    getCartList: () => void;
}

const BuyAgainAction: React.FC<IBuyAgainActionProps & IConnectCartProps> = (
    {
        config,
        orderItems,
        btnLabel,
        wrapperClassName,
        btnClassName,
        showBtnImage = true,
        getCartList,
        messagesClassName,
        showMessagesInPopup,
    },
) => {
    const [isPopupVisible, setIsPopupVisible] = useState(false);
    const [showBuyAgain, setShowBuyAgain] = useState(false);
    const [addToCartSuccess, setAddToCartSuccess] = useState(false);
    const [addToCartError, setAddToCartError] = useState(false);
    const [isAddingToCart, setIsAddingToCart] = useState(false);
    const [buyAgainErrorMessages, setBuyAgainErrorMessages] = useState<string[]>([]);
    const [buyAgainSuccessMessages, setBuyAgainSuccessMessages] = useState<string[]>([]);
    const [addToCartItems, setAddToCartItems] = useState([] as IAddToCartItem[]);

    const t = usePhraseTranslater();
    const { cartId } = config;

    const getAvailableOrderItems = (
        orderItems: TypeBuyAgainOrderItem[],
    ): TypeBuyAgainOrderItem[] => orderItems.filter((orderItem: TypeBuyAgainOrderItem) => {
        const {
            product_type: productType,
            product_is_salable: productIsSalable,
        } = orderItem;
        const buyAgainAllowedTypes = ['simple', 'configurable', 'bundle'];
        return buyAgainAllowedTypes.includes(productType) && productIsSalable;
    });

    const getAddToCartItem = (orderItem: TypeBuyAgainOrderItem): IAddToCartItem => {
        const {
            associated_product_sku: associatedProductSku,
            add_to_cart_options: addToCartOptions,
            quantity_ordered: qtyOrdered,
        } = orderItem;
        return {
            quantity: qtyOrdered,
            sku: associatedProductSku,
            selected_options: addToCartOptions || [],
        };
    };

    const getAddToCartOrderItems = (
        availableOrderItems: TypeBuyAgainOrderItem[],
    ): IAddToCartItem[] => {
        const addToCartOrderItems: IAddToCartItem[] = [];
        const addedBundles: { [key: string]: TypeBuyAgainOrderItem } = {};
        availableOrderItems.forEach((orderItem: TypeBuyAgainOrderItem) => {
            const { parentBundleItem, product_type: type } = orderItem;
            if (parentBundleItem || type === 'bundle') {
                const bundleOrderItem: TypeBuyAgainOrderItem = parentBundleItem || orderItem;
                const { id } = bundleOrderItem;
                if (!addedBundles[id]) {
                    addedBundles[id] = bundleOrderItem;
                    const addToCartBundle: IAddToCartItem = getAddToCartItem(bundleOrderItem);
                    addToCartOrderItems.push(addToCartBundle);
                }
            } else {
                const addToCartItem: IAddToCartItem = getAddToCartItem(orderItem);
                addToCartOrderItems.push(addToCartItem);
            }
        });
        return addToCartOrderItems;
    };

    const initAddToCartItems = (availableOrderItems: TypeBuyAgainOrderItem[]): void => {
        const itemsToAddToCart: IAddToCartItem[] = getAddToCartOrderItems(availableOrderItems);
        setAddToCartItems([
            ...itemsToAddToCart,
        ]);
    };

    const clearBuyAgainActionState = (): void => {
        setAddToCartSuccess(false);
        setAddToCartError(false);
    };

    const [addProductsToCart] = useMutation(ADD_PRODUCTS_TO_CART_MUTATION, {
        variables: {
            cartId,
            cartItems: addToCartItems,
        },
        onError: () => {
            if (getCartList) {
                getCartList();
            }

            setBuyAgainErrorMessages([t('Unknown error occurred during adding product to the cart.')]);
            setAddToCartError(true);
        },
        onCompleted: (data): void => {
            if (getCartList) {
                getCartList();
            }

            const cartItems = data?.addProductsToCart?.cart?.items ?? [];
            const addToCartUserErrors = data?.addProductsToCart?.user_errors ?? [];

            const addToCartSkus: string[] = addToCartItems.map((addToCartItem: IAddToCartItem) => {
                const { sku } = addToCartItem;
                return sku;
            });

            const successfullyAddedSkus: string[] = addToCartSkus.filter((addToCartSku: string) => {
                const foundCartItems = cartItems.find((cartItem) => {
                    const { product } = cartItem;
                    const { sku: addedSku } = product;
                    return addToCartSku === addedSku;
                });
                return foundCartItems !== undefined;
            });

            const hasSuccessfullyAddedItems = successfullyAddedSkus.length > 0;
            const hasUserErrors = addToCartUserErrors.length > 0;

            if (hasSuccessfullyAddedItems) {
                const skus = successfullyAddedSkus.join(', ');
                const successMessage = t('Item SKU(s) added to the cart successfully: %1', skus);
                setBuyAgainSuccessMessages([successMessage]);
                getCartList();
            }

            const errorMessages: string[] = addToCartUserErrors.map((error: IUserError) => {
                const { message } = error;
                return message;
            });
            setBuyAgainErrorMessages(errorMessages);

            setAddToCartError(hasUserErrors);
            setAddToCartSuccess(hasSuccessfullyAddedItems);
        },
    });

    const addProductsToCartAsyncAction = async (): Promise<void> => {
        setIsAddingToCart(true);
        await addProductsToCart();
    };

    const buyAgain = (event?: TypeBtnMouseOrKeyboardClickEvent): void => {
        if (event) event.preventDefault();
        clearBuyAgainActionState();
        addProductsToCartAsyncAction().finally(() => {
            setIsAddingToCart(false);
        });
    };

    const buttonLabel = btnLabel ?? t('Buy it again');
    const buyAgainLabel = (isAddingToCart ? t('Adding To Cart...') : buttonLabel);

    useEffect(() => {
        const availableOrderItems: TypeBuyAgainOrderItem[] = getAvailableOrderItems(orderItems);
        if (!availableOrderItems.length) {
            setShowBuyAgain(false);
            return;
        }

        initAddToCartItems(availableOrderItems);
        setShowBuyAgain(true);
    }, [orderItems]);

    useEffect(() => {
        setIsPopupVisible((addToCartSuccess || addToCartError));
    }, [addToCartSuccess, addToCartError]);

    const handleClosePopup = () => {
        setIsPopupVisible(false);
    };

    return (
        <>
            <div className={classes(styles.buyAgainWrapper, (wrapperClassName || ''))}>
                <SalesActionButton
                    action={buyAgain}
                    label={buyAgainLabel}
                    show={showBuyAgain}
                    isDisabled={isAddingToCart}
                    className={classes(styles.buyAgainBtn, (btnClassName || ''))}
                    dataTest="action-btn-buy-again"
                >
                    {showBtnImage && (<span className={styles.buyAgainImage} />)}
                </SalesActionButton>
                {showMessagesInPopup && (
                    <Popup
                        isVisible={isPopupVisible}
                        onClose={handleClosePopup}
                        classNameContentCnt={styles.buyAgainMessagesPopup}
                    >
                        <Messages
                            mainWrapperClassName={(messagesClassName || '')}
                            successMessages={buyAgainSuccessMessages}
                            showSuccessMessages={addToCartSuccess}
                            setShowSuccessMessages={setAddToCartSuccess}
                            errorMessages={buyAgainErrorMessages}
                            showErrorMessages={addToCartError}
                            setShowErrorMessages={setAddToCartError}
                            hideMessages={false}
                        />
                    </Popup>
                )}
                {!showMessagesInPopup && (
                    <Messages
                        mainWrapperClassName={(messagesClassName || '')}
                        successMessages={buyAgainSuccessMessages}
                        showSuccessMessages={addToCartSuccess}
                        setShowSuccessMessages={setAddToCartSuccess}
                        hideSuccessMessagesDelay={5000}
                        errorMessages={buyAgainErrorMessages}
                        showErrorMessages={addToCartError}
                        setShowErrorMessages={setAddToCartError}
                        hideErrorMessagesDelay={7000}
                        hideMessages
                    />
                )}
            </div>
        </>
    );
};

const BuyAgainActionConnectedToCart = connectCart(BuyAgainAction);

export { BuyAgainActionConnectedToCart as BuyAgainAction };
