import React, { useState } from 'react';
import { classes } from '@silkpwa/module/util/classes';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { AccessibleButton } from '@silkpwa/module/react-component/accessible-button';
import arrowDown from 'assets/images/down_arrow.svg';
import arrowUp from 'assets/images/up_arrow.svg';
import { ISalesTotal } from 'graphql/customer/customerOrdersInterfaces';
import { Link } from 'ui/component/custom/link';
import { SalesSummary } from '../sales-summary';
import styles from './style.css';

export interface ISalesItemsLayoutParams {
    salesEntityUId: string;
    salesEntityLabel: string;
    salesEntityNumber: string;
    salesEntityPrintUrl?: string;
    total?: ISalesTotal;
    className?: string;
    itemsClassName?: string;
    afterHeadLineContent?: React.ReactNode;
    children?: React.ReactNode;
}

export const SalesEntityLayout: React.FC<ISalesItemsLayoutParams> = (
    {
        salesEntityUId,
        salesEntityLabel,
        salesEntityNumber,
        salesEntityPrintUrl,
        total,
        className,
        itemsClassName,
        afterHeadLineContent,
        children,
    },
) => {
    const [isExpanded, setIsExpanded] = useState(true);
    const t = usePhraseTranslater();

    const toggleExpanded = (event?: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent): void => {
        if (event) event.preventDefault();
        setIsExpanded(!isExpanded);
    };

    const toggleItemsTitle = isExpanded ? t('Hide Items') : t('Show Items');

    return (
        <div className={classes(styles.salesEntityWrapper, (className || ''))} data-id={salesEntityUId}>
            <div
                className={classes(styles.salesEntityHeadline, {
                    [styles.hasBorderBottom]: afterHeadLineContent || isExpanded,
                })}
            >
                <div className={classes(styles.headlineRow, styles.left)}>
                    <div className={styles.salesEntityNumber}>{t('%1 #%2', salesEntityLabel, salesEntityNumber)}</div>
                    {salesEntityPrintUrl && (
                        <Link
                            label={t('Print %1', salesEntityLabel)}
                            href={salesEntityPrintUrl}
                            opacityOnHover
                            target="_blank"
                        />
                    )}
                </div>
                <div className={classes(styles.headlineRow, styles.right)}>
                    <AccessibleButton
                        tag="button"
                        className={classes(styles.toggleItemsButton)}
                        action={toggleExpanded}
                        aria-expanded={isExpanded}
                        tabIndex={0}
                    >
                        <div className={styles.toggleTitle}>{toggleItemsTitle}</div>
                        <img
                            className={styles.toggleArrow}
                            src={isExpanded ? arrowUp : arrowDown}
                            alt={toggleItemsTitle}
                        />
                    </AccessibleButton>
                </div>
            </div>
            {afterHeadLineContent && (
                <div
                    className={classes(styles.salesEntityHeadline, styles.secondary, {
                        [styles.hasBorderBottom]: isExpanded,
                    })}
                >
                    {afterHeadLineContent}
                </div>
            )}
            <div
                className={classes(styles.salesEntityItems, {
                    [styles.collapsed]: !isExpanded,
                    [styles.hasBottomRadius]: !total && isExpanded,
                }, (itemsClassName || ''))}
            >
                {children && (children)}
            </div>
            {total && (
                <SalesSummary
                    salesTotal={total}
                    className={styles.salesEntityTotals}
                    lineRowClassName={styles.summaryRow}
                />
            )}
        </div>
    );
};
