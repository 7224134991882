import React from 'react';
import { classes } from '@silkpwa/module/util/classes';
import { IWidgetProduct } from 'graphql/products/productsInterfaces';
import { DefaultImage } from './default-image';
import { ProductImage } from './product-image';
import { ProductName } from './product-name';
import { ProductPrice } from './product-price';
import styles from './style.css';

interface ProductCardProps {
    product: IWidgetProduct;
    className?: string;
    image?: JSX.Element;
    isVertical?: boolean;
    showProductName?: boolean;
    showProductPrice?: boolean;
}

export const ProductCard = (
    {
        product,
        className,
        image,
        isVertical = false,
        showProductName = true,
        showProductPrice = true,
    }: ProductCardProps,
) => (
    <div
        className={classes(styles.productCardWrapper, (className || ''), {
            [styles.vertical]: isVertical,
        })}
    >
        <div className={styles.productCardInnerWrap}>
            <ProductImage
                product={product}
                image={image || <DefaultImage product={product} />}
            />
            {showProductName && (
                <ProductName
                    className={styles.productName}
                    product={product}
                />
            )}
            {showProductPrice && (
                <ProductPrice
                    className={styles.productPrice}
                    product={product}
                />
            )}
        </div>
    </div>
);
