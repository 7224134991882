import React from 'react';
import { classes } from '@silkpwa/module/util/classes';
import styles from './style.css';

interface IInfoContainerProps {
    className?: string;
    dataTest?: string;
    children?: React.ReactNode;
}

export const InfoContainer: React.FC<IInfoContainerProps> = (
    {
        className,
        dataTest,
        children,
    },
) => (
    <div className={classes(styles.infoContainerWrapper, (className || ''))} data-test={`container-${dataTest}`}>
        {children || ''}
    </div>
);
