import React from 'react';
import { classes } from '@silkpwa/module/util/classes';
import { IWidgetProduct } from 'graphql/products/productsInterfaces';
import { ItemSlider } from 'ui/component/item-slider';
import { Images } from './images';
import { ProductCard } from './product-card';
import styles from './style.css';

interface IProductSliderProps {
    products: IWidgetProduct[];
    show?: number;
    className?: string;
    showProductName?: boolean;
    showProductPrice?: boolean;
    isVertical?: boolean;
}

export const ProductSlider: React.FC<IProductSliderProps> = (
    {
        products,
        show = 10,
        className,
        showProductName,
        showProductPrice,
        isVertical = false,
    },
) => (
    <div
        className={classes(styles.productSliderWrapper, (className ?? ''), {
            [styles.vertical]: isVertical,
        })}
    >
        <ItemSlider
            show={show}
            items={products}
            className={styles.itemSlider}
            arrowClassName={styles.arrow}
            leftArrowClassName={styles.leftArrow}
            rightArrowClassName={styles.rightArrow}
            itemsClassName={styles.items}
            renderItem={(product: IWidgetProduct) => (
                <ProductCard
                    product={product}
                    image={<Images product={product} images={product.thumbImages} isVertical={isVertical} />}
                    isVertical={isVertical}
                    showProductName={showProductName}
                    showProductPrice={showProductPrice}
                />
            )}
        />
    </div>
);
