import React, { useEffect, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import { classes } from '@silkpwa/module/util/classes';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { ICustomerOrder } from 'graphql/customer/customerOrdersInterfaces';
import { IWidgetProduct } from 'graphql/products/productsInterfaces';
import { recentlyViewedProductsVar } from 'ui/page/sales-pages/layouts/sales-layout/sales-layout-state';
import { InfoContainer } from './info-container';
import { OrderHeadline } from './order-headline';
import { OrderInformation } from './order-information';
import { DetailsWidget } from './details-widget';
import styles from './style.css';

interface ISalesDetailsLayoutProps {
    order?: ICustomerOrder;
    recentlyViewedWidget?: boolean;
    recommendedProductsWidget?: boolean;
    className?: string;
    itemsWrapperClassName?: string;
    orderNumberAsLink?: boolean;
    layoutPage?: 'invoices'|'shipments'|'refunds'|'returns';
    children: React.ReactNode;
}

export const SalesDetailsLayout: React.FC<ISalesDetailsLayoutProps> = (
    {
        order,
        recentlyViewedWidget,
        recommendedProductsWidget,
        className,
        itemsWrapperClassName,
        orderNumberAsLink,
        layoutPage,
        children,
    },
) => {
    const [showNumber, setShowNumber] = useState<number>(4);
    const [isMobile, setIsMobile] = useState<boolean>(false);
    const [showRecentlyViewedWidget] = useState<boolean>(recentlyViewedWidget || true);
    const [showRecommendedWidget, setShowRecommendedWidget] = useState<boolean>(recommendedProductsWidget || false);

    // TODO: once recommended products graphql is ready - replace the hardcoded `undefined` with graphql results.
    // TODO: the `recommendedProducts` should have a graphql and its reactive variable. For now it's stubbed.
    const [recommendedProducts, setRecommendedProducts] = useState<[]|undefined>(undefined);

    const t = usePhraseTranslater();

    const recentlyViewedProducts: IWidgetProduct[]|undefined = useReactiveVar(recentlyViewedProductsVar);

    const getShowNumber = () => {
        const { innerWidth } = window;
        const isDesktop = innerWidth > 1040;
        const isLargeMobile = innerWidth < 1041 && innerWidth >= 876;
        const isMediumMobile = innerWidth < 876 && innerWidth >= 591;
        const isSmallMobile = innerWidth < 591 && innerWidth >= 401;
        const isVerySmallMobile = innerWidth < 401;

        if (isDesktop || isLargeMobile) {
            return 4;
        }

        if (isMediumMobile) {
            return 3;
        }

        if (isSmallMobile) {
            return 2;
        }

        if (isVerySmallMobile) {
            return 1;
        }

        return 4;
    };

    const checkIsMobile = () => {
        const { innerWidth } = window;
        const isMobileView = innerWidth < 1041;
        setIsMobile(isMobileView);
    };

    useEffect(() => {
        checkIsMobile();
        const handleResize = () => {
            const show = getShowNumber();
            setShowNumber(show);
            checkIsMobile();
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        // TODO: once recommended products graphql is ready - remove this hardcoded effect.
        // TODO: for now - it's forcefully not enabled to be shown.
        setShowRecommendedWidget(false);
        setRecommendedProducts([]);
    }, []);

    return (
        <div
            className={classes(
                styles.salesDetailsWrapper,
                (className || ''),
            )}
            data-test="sales-details"
        >
            <div className={styles.salesDetails}>
                <div className={classes(styles.detailsRow, styles.left)}>
                    {order && (
                        <>
                            <div className={styles.orderHeadLine}>
                                <OrderHeadline
                                    order={order}
                                    orderNumberAsLink={orderNumberAsLink}
                                    layoutPage={layoutPage}
                                />
                            </div>
                            <InfoContainer dataTest="sales-info">
                                <OrderInformation order={order} />
                            </InfoContainer>
                        </>
                    )}
                    <InfoContainer dataTest="sales-items" className={(itemsWrapperClassName || '')}>
                        {children}
                    </InfoContainer>
                    {showRecommendedWidget && !!(recommendedProducts && recommendedProducts.length > 0) && (
                        <InfoContainer dataTest="recommended-products">
                            <DetailsWidget
                                products={recommendedProducts}
                                show={showNumber}
                                label={t('Recommended Products')}
                                labelClassName={styles.horizontalLabel}
                            />
                        </InfoContainer>
                    )}
                </div>
                {showRecentlyViewedWidget && !!(recentlyViewedProducts && recentlyViewedProducts.length > 0) && (
                    <div className={classes(styles.detailsRow, styles.right)}>
                        <InfoContainer dataTest="browsing-history">
                            <DetailsWidget
                                products={recentlyViewedProducts}
                                show={showNumber}
                                label={t('Browsing History')}
                                labelClassName={(isMobile ? styles.horizontalLabel : undefined)}
                                isVertical={!isMobile}
                            />
                        </InfoContainer>
                    </div>
                )}
            </div>
        </div>
    );
};
