import React from 'react';
import { classes } from '@silkpwa/module/util/classes';
import styles from './style.css';


interface IHeaderInfoParams {
    title?: string;
    className?: string;
    titleClassName?: string;
    textClassName?: string;
    children: React.ReactNode;
}

export const HeaderInfo: React.FC<IHeaderInfoParams> = (
    {
        title,
        className,
        titleClassName,
        textClassName,
        children,
    },
) => (
    <div className={classes(styles.headerInfo, (className || ''))}>
        {title && (
            <div className={classes(styles.headerTitle, (titleClassName || ''))}>
                {title}
            </div>
        )}
        <div className={classes(styles.headerText, (textClassName || ''))}>
            {children}
        </div>
    </div>
);
