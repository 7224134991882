import React from 'react';
import { useReactiveVar } from '@apollo/client';
import { classes } from '@silkpwa/module/util/classes';
import { getCountryName, getAddressRegionCode } from 'graphql/customer/util/get-order-address-data';
import { IOrderAddress } from 'graphql/customer/customerOrdersInterfaces';
import { ICountry } from 'graphql/store/country';
import { countriesVar } from 'ui/page/sales-pages/layouts/sales-layout/sales-layout-state';
import styles from './style.css';

interface IOrderAddressProps {
    orderAddress: IOrderAddress;
    className?: string;
}

export const OrderAddress: React.FC<IOrderAddressProps> = (
    {
        orderAddress,
        className,
    },
) => {
    const countries: ICountry[] = useReactiveVar(countriesVar);

    const {
        firstname,
        lastname,
        middlename,
        street,
        city,
        postcode,
        country_code: countryCode,
    } = orderAddress;

    const countryName = getCountryName(countries, countryCode);
    const regionCode = getAddressRegionCode(countries, orderAddress);

    const middle = middlename ? ` ${middlename} ` : ' ';
    const fullName = `${firstname}${middle}${lastname}`;
    const fullStreet = street.length > 1 ? street.join(' ') : street[0];
    const regionAndCity = `${city}, ${regionCode} ${postcode}`;

    return (
        <div className={classes(styles.orderAddress, (className || ''))}>
            <div className={classes(styles.addressLine, styles.fullName)}>
                <span>{fullName}</span>
            </div>
            <div className={classes(styles.addressLine, styles.street)}>
                <span>{fullStreet}</span>
            </div>
            <div className={classes(styles.addressLine, styles.regionAndCity)}>
                <span>{regionAndCity}</span>
            </div>
            <div className={classes(styles.addressLine, styles.country)}>
                <span>{countryName}</span>
            </div>
        </div>
    );
};
