/* eslint-disable camelcase */
import {
    IOrderShipmentItem,
    IOrderInvoiceItem,
    IOrderCreditMemoItem,
    IOrderItem,
} from 'graphql/customer/customerOrdersInterfaces';

export const getInvoiceOrderItem = (invoiceItem: IOrderInvoiceItem): IOrderItem => {
    const {
        order_item: invoiceOrderItem,
        product_sale_price: salePrice,
        quantity_invoiced: qtyInvoiced,
    } = invoiceItem;
    return {
        ...invoiceOrderItem,
        product_sale_price: salePrice,
        quantity_ordered: qtyInvoiced,
    };
};

export const getShipmentOrderItem = (shipmentItem: IOrderShipmentItem): IOrderItem => {
    const {
        order_item: shipmentOrderItem,
        product_sale_price: salePrice,
        quantity_shipped: qtyShipped,
    } = shipmentItem;
    return {
        ...shipmentOrderItem,
        product_sale_price: salePrice,
        quantity_ordered: qtyShipped,
    };
};

export const getRefundOrderItem = (refundItem: IOrderCreditMemoItem): IOrderItem => {
    const {
        order_item: refundOrderItem,
        product_sale_price: salePrice,
        quantity_refunded: qtyRefunded,
    } = refundItem;
    return {
        ...refundOrderItem,
        product_sale_price: salePrice,
        quantity_ordered: qtyRefunded,
    };
};


export const getInvoiceOrderItems = (invoiceItems: IOrderInvoiceItem[]): IOrderItem[] => {
    const invoiceOrderItems: IOrderItem[] = [];
    invoiceItems.forEach((invoiceItem: IOrderInvoiceItem) => {
        const foundInvoiceOrderItem = getInvoiceOrderItem(invoiceItem);
        if (foundInvoiceOrderItem) {
            invoiceOrderItems.push(foundInvoiceOrderItem);
        }
    });
    return invoiceOrderItems;
};

export const getShipmentOrderItems = (shipmentItems: IOrderShipmentItem[]): IOrderItem[] => {
    const shipmentOrderItems: IOrderItem[] = [];
    shipmentItems.forEach((shipmentItem: IOrderShipmentItem) => {
        const foundShipmentOrderItem = getShipmentOrderItem(shipmentItem);
        if (foundShipmentOrderItem) {
            shipmentOrderItems.push(foundShipmentOrderItem);
        }
    });
    return shipmentOrderItems;
};

export const getRefundOrderItems = (refundItems: IOrderCreditMemoItem[]): IOrderItem[] => {
    const refundOrderItems: IOrderItem[] = [];
    refundItems.forEach((refundItem: IOrderCreditMemoItem) => {
        const foundRefundOrderItem = getRefundOrderItem(refundItem);
        if (foundRefundOrderItem) {
            refundOrderItems.push(foundRefundOrderItem);
        }
    });
    return refundOrderItems;
};
