import { IOrderAddress } from '../customerOrdersInterfaces';
import { IAvailableRegion, ICountry } from '../../store/country';

const findCountryByCode = (
    countries: ICountry[],
    countryCode: string,
): ICountry|undefined => countries.find((country: ICountry) => country.id === countryCode);

const findRegionCode = (
    country: ICountry|undefined,
    regionId: string,
    regionName: string|null,
): string => {
    let regionCode = regionName;
    if (country && country.available_regions) {
        const foundRegion: IAvailableRegion|undefined = country.available_regions.find(
            availableRegion => availableRegion.id === parseInt(regionId, 10),
        );
        if (foundRegion) {
            const { code } = foundRegion;
            regionCode = code;
        }
    }

    return `${regionCode}`;
};

export const getCountryName = (
    countries: ICountry[],
    countryCode: string,
): string => {
    const foundCountry: ICountry|undefined = findCountryByCode(countries, countryCode);
    return foundCountry ? foundCountry.full_name_locale : countryCode;
};

export const getAddressRegionCode = (
    countries: ICountry[],
    address: IOrderAddress,
): string => {
    const {
        region_id: regionId,
        region: regionName,
        country_code: countryCode,
    } = address;
    const foundCountry: ICountry|undefined = findCountryByCode(countries, countryCode);
    return findRegionCode(foundCountry, regionId, regionName);
};

export const getMapsAddressLine = (
    countries: ICountry[],
    address: IOrderAddress,
): string => {
    const { street, city, postcode } = address;
    const regionCode = getAddressRegionCode(countries, address);
    const fullStreet = street.length > 1 ? street.join(' ') : street[0];
    return `${fullStreet}, ${city} ${regionCode}, ${postcode}`;
};
