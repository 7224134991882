import React, { useEffect, useState } from 'react';
import { classes } from '@silkpwa/module/util/classes';
import styles from './style.css';

interface IMessagesProps {
    successMessages?: string[];
    showSuccessMessages?: boolean;
    setShowSuccessMessages?: (show: boolean) => void;
    hideSuccessMessagesDelay?: number;
    errorMessages?: string[];
    showErrorMessages?: boolean;
    setShowErrorMessages?: (show: boolean) => void;
    hideErrorMessagesDelay?: number;
    hideMessages?: boolean;
    mainWrapperClassName?: string;
    messagesWrapperClassName?: string;
    successMessageClassName?: string;
    errorMessageClassName?: string;
}

export const Messages: React.FC<IMessagesProps> = (
    {
        successMessages,
        showSuccessMessages,
        setShowSuccessMessages,
        errorMessages,
        showErrorMessages,
        setShowErrorMessages,
        hideSuccessMessagesDelay = 0,
        hideErrorMessagesDelay = 0,
        hideMessages = true,
        mainWrapperClassName,
        messagesWrapperClassName,
        successMessageClassName,
        errorMessageClassName,
    },
) => {
    const [successTimer, setSuccessTimer] = useState(null as any);
    const [errorTimer, setErrorTimer] = useState(null as any);

    useEffect(() => {
        if (!hideMessages) {
            return;
        }

        if (showSuccessMessages && setShowSuccessMessages) {
            if (successTimer) {
                clearTimeout(successTimer);
                setSuccessTimer(null);
            }

            const newSuccessTimer = setTimeout(() => {
                setShowSuccessMessages(false);
            }, hideSuccessMessagesDelay);
            setSuccessTimer(newSuccessTimer);
        }
    }, [showSuccessMessages]);

    useEffect(() => {
        if (!hideMessages) {
            return;
        }

        if (showErrorMessages && setShowErrorMessages) {
            if (errorTimer) {
                clearTimeout(errorTimer);
                setErrorTimer(null);
            }

            const newErrorTimer = setTimeout(() => {
                setShowErrorMessages(false);
            }, hideErrorMessagesDelay);
            setErrorTimer(newErrorTimer);
        }
    }, [showErrorMessages]);

    if (!successMessages && !errorMessages) {
        return null;
    }

    return (
        <div className={classes(styles.messagesMainWrapper, (mainWrapperClassName || ''))}>
            <div
                className={classes(styles.messagesWrapper, {
                    [styles.fadeIn]: showSuccessMessages,
                    [styles.fadeOut]: !showSuccessMessages,
                }, (messagesWrapperClassName || ''))}
            >
                {successMessages && successMessages.map(
                    (success: string) => (
                        <span className={classes(styles.success, (successMessageClassName || ''))}>{success}</span>
                    ),
                )}
            </div>
            <div
                className={classes(styles.messagesWrapper, {
                    [styles.fadeIn]: showErrorMessages,
                    [styles.fadeOut]: !showErrorMessages,
                }, (messagesWrapperClassName || ''))}
            >
                {errorMessages && errorMessages.map(
                    (error: string) => (
                        <span className={classes(styles.error, (errorMessageClassName || ''))}>{error}</span>
                    ),
                )}
            </div>
        </div>
    );
};
