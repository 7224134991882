import React from 'react';
import { IWidgetProduct } from 'graphql/products/productsInterfaces';
import { usePriceFormatter, usePriceRounder } from '@silkpwa/module/ecommerce-catalog/config';
import { classes } from '@silkpwa/module/util/classes';
import styles from './style.css';

interface IProductPriceProps {
    product: IWidgetProduct;
    className?: string;
}

export const ProductPrice: React.FC<IProductPriceProps> = (
    {
        product,
        className,
    },
) => {
    const formatPrice = usePriceFormatter();
    const roundPrice = usePriceRounder();

    const getFormattedPrice = (price: number) => formatPrice(roundPrice(price));

    const getPrice = () => {
        const { price_range: priceRange } = product;

        const {
            minimum_price: minPrice,
            maximum_price: maxPrice,
        } = priceRange;

        const { final_price: minFinalPrice } = minPrice;
        const { final_price: maxFinalPrice } = maxPrice;

        const { value: minFinalPriceValue } = minFinalPrice;
        const { value: maxFinalPriceValue } = maxFinalPrice;

        if (!minFinalPriceValue && !maxFinalPriceValue) {
            return '';
        }

        const minPriceView = getFormattedPrice(minFinalPriceValue);
        const maxPriceView = getFormattedPrice(minFinalPriceValue);

        if (minFinalPriceValue === maxFinalPriceValue) {
            return minPriceView;
        }

        return `${minPriceView} - ${maxPriceView}`;
    };

    const price = getPrice();

    return (
        <div className={classes(styles.productPrice, (className || ''))}>
            {price}
        </div>
    );
};
