import React, { useState, useEffect } from 'react';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { Navigate, useNavigate } from '@silkpwa/module/router/use-navigate';
import { navigateToUrl } from 'ui/util/navigate-to-url';
import { ISalesLayoutConfig } from 'ui/page/sales-pages/layouts/sales-layout/sales-layout-state';
import {
    ICustomerOrder,
    IOrderItem,
    TypeSplitOrderItem,
    IOrderItemTracking,
    ITracking,
} from 'graphql/customer/customerOrdersInterfaces';
import {
    SalesActionButton,
    TypeBtnMouseOrKeyboardClickEvent,
} from 'ui/component/sales-components/sales-action-button';
import Popup from 'ui/component/popup/popup';
import { Tracking } from '../../../../order-details/order-entity/shipments/shipment/tracking';
import styles from '../style.css';

interface IItemActionsProps {
    config: ISalesLayoutConfig;
    order: ICustomerOrder;
    orderItem: IOrderItem|TypeSplitOrderItem;
    showSupportButton: boolean;
    showReturnButton: boolean;
    showReviewButton: boolean;
    showTrackButton?: boolean;
    filterTracksByShipmentUid?: string;
}

export const ItemActions: React.FC<IItemActionsProps> = (
    {
        config,
        order,
        orderItem,
        showSupportButton,
        showReturnButton,
        showReviewButton,
        showTrackButton,
        filterTracksByShipmentUid,
    },
) => {
    const [allTracksCount, setAllTracksCount] = useState<number>(0);
    const [itemHasShipmentTracks, setItemHasShipmentTracks] = useState<boolean>(false);
    const [isPopupVisible, setIsPopupVisible] = useState<boolean>(false);
    const [itemShipmentTracks, setItemShipmentTracks] = useState<IOrderItemTracking[]>([]);
    const t = usePhraseTranslater();
    const navigate: Navigate = useNavigate();

    const {
        order_urls: orderUrls,
    } = order;
    const { create_returns_url: createReturnsUrl, shipment_url: shipmentUrl } = orderUrls;
    const {
        product_url: productUrl,
        itemTracking,
    } = orderItem;
    const { salesConfig } = config;
    const { productSupportUrl } = salesConfig;
    const writeReviewUrl = `${productUrl}#write-review`;

    const getFistShipmentTrackingUrl = (): string|undefined => {
        const firstShipmentTracking: IOrderItemTracking|undefined = itemShipmentTracks.shift();
        const firstShipmentTracks: ITracking[] = firstShipmentTracking?.tracks ?? [];
        const firstShipmentTrack: ITracking|undefined = firstShipmentTracks.shift();
        return firstShipmentTrack?.trackingUrl;
    };

    const trackPackage = (event?: TypeBtnMouseOrKeyboardClickEvent): void => {
        if (event) event.preventDefault();
        if (allTracksCount === 1) {
            const firstShipmentTrackingUrl: string|undefined = getFistShipmentTrackingUrl();
            const trackingUrl: string = firstShipmentTrackingUrl || shipmentUrl;
            navigateToUrl({
                navigate,
                url: trackingUrl,
            });
            return;
        }

        setIsPopupVisible(true);
    };

    const productSupport = (event?: TypeBtnMouseOrKeyboardClickEvent): void => {
        if (event) event.preventDefault();
        navigateToUrl({
            navigate,
            url: productSupportUrl,
        });
    };

    const createReturn = (event?: TypeBtnMouseOrKeyboardClickEvent): void => {
        if (event) event.preventDefault();
        navigateToUrl({
            navigate,
            url: createReturnsUrl,
        });
    };

    const reviewProduct = (event?: TypeBtnMouseOrKeyboardClickEvent): void => {
        if (event) event.preventDefault();
        navigateToUrl({
            navigate,
            url: writeReviewUrl,
        });
    };

    const handleClosePopup = () => {
        setIsPopupVisible(false);
    };

    const getFilteredShipmentTracks = (
        itemTracking: IOrderItemTracking[],
        findByShipmentUid: string,
    ): IOrderItemTracking[] => itemTracking.filter((itemTracking: IOrderItemTracking) => {
        const { shipmentUid } = itemTracking;
        return shipmentUid === findByShipmentUid;
    });

    const getTracksCount = (itemTracking: IOrderItemTracking[]): number => {
        let count = 0;
        itemTracking.forEach((itemTracking: IOrderItemTracking) => {
            const { tracks } = itemTracking;
            const { length } = tracks;
            count += length;
        });
        return count;
    };

    useEffect(() => {
        const shipmentTracks: IOrderItemTracking[] = filterTracksByShipmentUid && filterTracksByShipmentUid.length > 0
            ? getFilteredShipmentTracks(itemTracking, filterTracksByShipmentUid)
            : itemTracking;
        setItemShipmentTracks(shipmentTracks);
    }, [filterTracksByShipmentUid, itemTracking]);

    useEffect(() => {
        const tracksCount: number = getTracksCount(itemShipmentTracks);
        setAllTracksCount(tracksCount);
        setItemHasShipmentTracks(tracksCount > 0);
    }, [itemShipmentTracks]);

    return (
        <>
            <SalesActionButton
                action={trackPackage}
                label={t('Track Package')}
                show={(showTrackButton && itemHasShipmentTracks)}
                className={styles.trackPackage}
                dataTest="action-btn-track-package"
            />
            <Popup
                isVisible={isPopupVisible}
                onClose={handleClosePopup}
                className={styles.itemTracksPopup}
                classNameContentCnt={styles.itemTracksContentPopup}
            >
                <div className={styles.orderItemShipmentTracks}>
                    {itemHasShipmentTracks && itemShipmentTracks.map(
                        (itemShipmentTrack: IOrderItemTracking) => {
                            const { shipmentUid, tracks } = itemShipmentTrack;
                            return (
                                <div className={styles.itemShipmentTracks} data-shipment-uid={shipmentUid}>
                                    {tracks.length > 0 && (
                                        <Tracking
                                            tracks={tracks}
                                            className={styles.orderItemTracks}
                                        />
                                    )}
                                </div>
                            );
                        },
                    )}
                </div>
            </Popup>
            <SalesActionButton
                action={productSupport}
                label={t('Product Support')}
                show={showSupportButton}
                className={styles.productSupport}
                dataTest="action-btn-product-support"
            />
            <SalesActionButton
                action={createReturn}
                label={t('Return or Replace')}
                show={showReturnButton}
                className={styles.returnOrReplace}
                dataTest="action-btn-create-return"
            />
            <SalesActionButton
                action={reviewProduct}
                label={t('Write a Product Review')}
                show={showReviewButton}
                className={styles.writeProductReview}
                dataTest="action-btn-product-review"
            />
        </>
    );
};
