import React from 'react';
import { classes } from '@silkpwa/module/util/classes';
import { IWidgetProduct } from 'graphql/products/productsInterfaces';
import { ProductSlider } from 'ui/component/custom/product-slider';
import styles from './style.css';

interface IDetailsWidgetProps {
    products: IWidgetProduct[];
    label: string;
    labelClassName?: string;
    show?: number;
    isVertical?: boolean;
}

export const DetailsWidget: React.FC<IDetailsWidgetProps> = (
    {
        products,
        label,
        labelClassName,
        show = 4,
        isVertical = false,
    },
) => (
    <div className={classes(styles.detailsWidgetWrapper, { [styles.vertical]: isVertical })}>
        <div className={classes(styles.historyRow, styles.title, (labelClassName || ''))}>
            <div>{label}</div>
        </div>
        {!!products.length && (
            <ProductSlider
                products={products}
                className={classes(styles.historyRow, styles.historyProducts)}
                show={show}
                isVertical={isVertical}
                showProductName={false}
                showProductPrice={false}
            />
        )}
    </div>
);
