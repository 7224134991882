import React from 'react';
import { ISalesLayoutConfig } from 'ui/page/sales-pages/layouts/sales-layout/sales-layout-state';
import {
    ICustomerOrder,
    IOrderItem,
    TypeSplitOrderItem,
} from 'graphql/customer/customerOrdersInterfaces';
import { OrderItem } from './item';
import styles from './style.css';

export interface IOrderItemsParams {
    config: ISalesLayoutConfig;
    order: ICustomerOrder;
    orderItems?: IOrderItem[];
    setWrapperHasZIndex?: (hasZIndex: boolean) => void;
    orderDetails?: boolean;
}

export const OrderItems: React.FC<IOrderItemsParams> = (
    {
        config,
        order,
        orderItems,
        setWrapperHasZIndex,
        orderDetails = false,
    },
) => {
    const finalOrderItems: IOrderItem[] = orderItems || order.items;
    if (!finalOrderItems || !finalOrderItems.length) {
        return null;
    }

    return (
        <div className={styles.orderItems}>
            {finalOrderItems.map(
                (orderItem: IOrderItem) => (
                    <>
                        {orderItem.splitItems && orderItem.splitItems.map(
                            (splitItem: TypeSplitOrderItem) => (
                                <OrderItem
                                    config={config}
                                    order={order}
                                    orderItem={splitItem}
                                    setWrapperHasZIndex={setWrapperHasZIndex}
                                    isSplitItem
                                    orderDetails={orderDetails}
                                />
                            ),
                        )}
                        {!orderItem.splitItems && (
                            <OrderItem
                                config={config}
                                order={order}
                                orderItem={orderItem}
                                setWrapperHasZIndex={setWrapperHasZIndex}
                                orderDetails={orderDetails}
                            />
                        )}
                    </>
                ),
            )}
        </div>
    );
};
