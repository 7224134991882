import React from 'react';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { ICustomerOrder } from 'graphql/customer/customerOrdersInterfaces';
import { Link } from 'ui/component/custom/link';
import { SalesDetailsLayout } from 'ui/component/sales-components/sales-details-layout';
import styles from './style.css';

interface IOrderEntityProps {
    order: ICustomerOrder;
    layoutPage?: 'invoices'|'shipments'|'refunds'|'returns';
    entityLabel?: string;
    entityPrintAllUrl?: string;
    children?: React.ReactNode;
}

export const OrderEntity: React.FC<IOrderEntityProps> = (
    {
        order,
        layoutPage,
        entityLabel,
        entityPrintAllUrl,
        children,
    },
) => {
    const t = usePhraseTranslater();
    return (
        <SalesDetailsLayout
            order={order}
            itemsWrapperClassName={styles.orderEntityWrapper}
            orderNumberAsLink
            layoutPage={layoutPage}
        >
            <>
                {entityPrintAllUrl && (
                    <div className={styles.orderEntityHeadline}>
                        <Link
                            label={entityLabel ? t('Print All %1', entityLabel) : t('Print All')}
                            href={entityPrintAllUrl}
                            opacityOnHover
                            target="_blank"
                        />
                    </div>
                )}
                <div className={styles.orderEntityWrapper}>
                    {children && (children)}
                </div>
            </>
        </SalesDetailsLayout>
    );
};
