import React from 'react';
import { classes } from '@silkpwa/module/util/classes';
import { ActionButton } from 'ui/component/custom/action-button';
import styles from './style.css';

export type TypeBtnMouseOrKeyboardClickEvent = React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent;

interface ISalesActionButtonProps {
    action: (e?: TypeBtnMouseOrKeyboardClickEvent) => void;
    label: string;
    show?: boolean;
    isDisabled?: boolean;
    className?: string;
    dataTest?: string;
    childrenBeforeLabel?: boolean;
    children?: React.ReactNode;
}

export const SalesActionButton: React.FC<ISalesActionButtonProps> = (
    {
        action,
        label,
        show = true,
        isDisabled = false,
        className,
        dataTest,
        childrenBeforeLabel = true,
        children,
    },
) => (
    <ActionButton
        action={action}
        label={label}
        show={show}
        tag="button"
        disabled={isDisabled}
        btnClassName={classes(styles.salesActionButton, (className ?? ''))}
        labelClassName={styles.salesActionButtonLabel}
        dataTest={dataTest}
        childrenBeforeLabel={childrenBeforeLabel}
    >
        {children && (children)}
    </ActionButton>
);
