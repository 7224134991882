import React from 'react';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { classes } from '@silkpwa/module/util/classes';
import { ISalesLayoutConfig } from 'ui/page/sales-pages/layouts/sales-layout/sales-layout-state';
import {
    ICustomerOrder,
    IOrderShipment,
    IOrderItem,
    TypeSplitOrderItem,
    IOrderShipmentItem,
} from 'graphql/customer/customerOrdersInterfaces';
import { ProductImage } from 'ui/component/sales-components/product-image';
import { ProductDetails } from 'ui/component/sales-components/product-details';
import { LinkPopup } from 'ui/component/custom/link-popup';
import { BuyAgainAction } from './buy-again-action';
import { ItemActions } from './item-actions';
import { EmbroideryDetails } from './emb-details';
import styles from './style.css';

export interface IOrderItemParams {
    config: ISalesLayoutConfig;
    order: ICustomerOrder;
    orderItem: IOrderItem|TypeSplitOrderItem;
    className?: string;
    setWrapperHasZIndex?: (hasZIndex: boolean) => void;
    isSplitItem?: boolean;
    orderDetails?: boolean;
    showItemStatus?: boolean;
    showPriceTotal?: boolean;
    priceTotalLabel?: string;
    useQty?: number;
    usePrice?: number;
    filterTracksByShipmentUid?: string;
}

export const OrderItem: React.FC<IOrderItemParams> = (
    {
        config,
        order,
        orderItem,
        className,
        setWrapperHasZIndex,
        isSplitItem = false,
        orderDetails = false,
        showItemStatus = true,
        showPriceTotal = false,
        priceTotalLabel,
        useQty,
        usePrice,
        filterTracksByShipmentUid,
    },
) => {
    const t = usePhraseTranslater();

    const { shipments } = order;
    const {
        id: currentItemId,
        statusLabel,
        product_image_url: productImageUrl,
        product_name: productName,
        product_type: productType,
        quantity_ordered: qtyOrdered,
        quantity_invoiced: qtyInvoiced,
        quantity_shipped: qtyShipped,
        quantity_refunded: qtyRefunded,
        quantity_returned: qtyReturned,
        quantity_canceled: qtyCanceled,
        product_url: productUrl,
        eligible_for_return: isEligibleForReturn,
        rma_available_qty: rmaAvailableQty,
        parentBundleItem,
        embroideryItems,
    } = orderItem;

    const isBundle = productType === 'bundle';
    const hasParentBundle = !!parentBundleItem;

    const writeReviewUrl = `${productUrl}#write-review`;

    const hasEmbroideredItems = !!(embroideryItems && embroideryItems.length > 0);
    const hasShipments = shipments && shipments.length > 0;

    const splitOrderItem = orderItem as TypeSplitOrderItem;
    const { finalQuantity } = splitOrderItem;
    const itemQuantity = isSplitItem ? finalQuantity : qtyOrdered;
    const counterQty = useQty !== undefined ? useQty : itemQuantity;

    const getCurrentShipmentItems = (shipment: IOrderShipment) => shipment.items.filter((shipmentItem) => {
        const { order_item: shipmentOrderItem } = shipmentItem;
        const { id: orderItemId, parent_item_id: parentOrderItemId } = shipmentOrderItem;
        return currentItemId === orderItemId || (isBundle && currentItemId === parentOrderItemId);
    });
    const getCurrentShipments = (shipments: IOrderShipment[]) => shipments.filter((shipment) => {
        const foundShipmentItems = getCurrentShipmentItems(shipment);
        return foundShipmentItems && foundShipmentItems.length > 0;
    });

    const currentItemShipments = getCurrentShipments(shipments);
    const currentItemInShipments = currentItemShipments.length > 0;
    const isShipmentItemInShipments = hasShipments && currentItemInShipments;

    const getRmaEligibleDate = (): false|string => {
        if (!hasShipments) {
            return false;
        }

        const itemShipments = shipments.filter((shipment) => {
            const { rma_eligible_date: rmaEligibleDate } = shipment;
            const hasRmaEligibleDate = rmaEligibleDate && rmaEligibleDate.length > 0;
            const foundItems = getCurrentShipmentItems(shipment);
            return foundItems.length > 0 && hasRmaEligibleDate;
        });

        const { length } = itemShipments;
        if (length === 0) {
            return false;
        }

        /**
         * First shipment is the latest one as it is applied DESC by Date.
         */
        const latestShipment = itemShipments[0];
        const { rma_eligible_date: eligibleDate } = latestShipment;
        return eligibleDate;
    };

    const rmaEligibleDate = getRmaEligibleDate();
    const getBundleItemsRmaAvailableTotalQty = (): number => {
        if (!currentItemShipments.length) {
            return 0;
        }

        let totalItemsQty = 0;
        const simpleItems: { [key: string]: IOrderItem } = {};
        currentItemShipments.forEach((shipment: IOrderShipment) => {
            const { items: shipmentItems } = shipment;
            shipmentItems.forEach((shipmentItem: IOrderShipmentItem) => {
                const { order_item: shipmentOrderItem } = shipmentItem;
                const {
                    parent_item_id: parentId,
                    product_sku: sku,
                    rma_available_qty: rmaOrderItemQty,
                } = shipmentOrderItem;
                if (currentItemId === parentId && !simpleItems[sku]) {
                    simpleItems[sku] = shipmentOrderItem;
                    totalItemsQty += rmaOrderItemQty;
                }
            });
        });

        return totalItemsQty;
    };

    const finalRmaAvailableQty = isBundle ? getBundleItemsRmaAvailableTotalQty() : rmaAvailableQty;
    const rmaAvailable = isEligibleForReturn && finalRmaAvailableQty > 0;

    const isDigitizingFee = productName === 'Embroidery Digitizing Fee';
    const showTrackButton = !isDigitizingFee && isShipmentItemInShipments;
    const showSupportButton = true;
    /**
     * No need to check whether `isShipmentItemInShipments` or not as it is already checked on the BE
     * when checking the `isEligibleForReturn`, which is used for `rmaAvailable`.
     */
    const showReturnButton = !isDigitizingFee && rmaAvailable;
    const showReviewButton = !isDigitizingFee && writeReviewUrl.length > 0;

    const buyAgainOrderItem = hasParentBundle ? parentBundleItem : orderItem;
    const buyAgainItems: (IOrderItem|TypeSplitOrderItem)[] = useQty !== undefined
        ? [
            {
                ...buyAgainOrderItem,
                quantity_ordered: useQty,
            },
        ]
        : [buyAgainOrderItem];

    interface IQtyOption {
        label: string;
        value: number;
    }

    const quantities: IQtyOption[] = [
        {
            label: t('Qty Ordered:'),
            value: qtyOrdered,
        },
        {
            label: t('Qty Invoiced:'),
            value: qtyInvoiced,
        },
        {
            label: t('Qty Shipped:'),
            value: qtyShipped,
        },
        {
            label: t('Qty Refunded:'),
            value: qtyRefunded,
        },
        {
            label: t('Qty Returned:'),
            value: qtyReturned,
        },
        {
            label: t('Qty Canceled:'),
            value: qtyCanceled,
        },
    ];

    const filteredQuantities = quantities.filter((qtyOption: IQtyOption) => {
        const { label, value } = qtyOption;
        return value > 0 && !((isBundle || hasParentBundle) && label !== t('Qty Ordered:'));
    });

    if (!orderItem || !orderItem.id) {
        return null;
    }

    return (
        <div
            className={classes(styles.orderItem, {
                [styles.orderDetails]: orderDetails,
            }, (className || ''))}
        >
            {showItemStatus && (
                <div className={classes(styles.itemDetailsWrap, styles.itemStatus)}>
                    <span className={styles.label}>{statusLabel}</span>
                    {!isDigitizingFee && (
                        <LinkPopup
                            beforeLabel={t('Qty')}
                            linkLabel={t('Details')}
                            wrapperClassName={styles.qtyDetailsWrapper}
                            contentClassName={styles.quantitiesWrapper}
                        >
                            <div className={styles.quantities}>
                                {filteredQuantities.map(({ label, value }: IQtyOption) => (
                                    <div className={styles.quantity}>
                                        <div className={styles.qtyLabel}>{label}</div>
                                        <div className={styles.qtyValue}>{value}</div>
                                    </div>
                                ))}
                            </div>
                        </LinkPopup>
                    )}
                </div>
            )}
            <div className={classes(styles.itemDetailsWrap, styles.itemInfo)}>
                <ProductImage
                    className={classes(styles.itemDetail, styles.itemImage)}
                    productImageUrl={productImageUrl}
                    productName={productName}
                    width={160}
                    height={180}
                    counter={counterQty}
                />
                <div className={classes(styles.itemDetail, styles.productDetails)}>
                    <ProductDetails
                        config={config}
                        item={orderItem}
                        isSplitItem={isSplitItem}
                        productNameAsLink={!isDigitizingFee}
                        showPriceTotal={orderDetails || showPriceTotal}
                        priceTotalLabel={priceTotalLabel}
                        useQty={useQty}
                        usePrice={usePrice}
                    >
                        <>
                            {hasEmbroideredItems && (
                                <EmbroideryDetails
                                    parentItem={orderItem}
                                    embroideryItems={embroideryItems}
                                    label={t('Details')}
                                    beforeLabel={t('Embroidered')}
                                    setWrapperHasZIndex={setWrapperHasZIndex}
                                />
                            )}
                        </>
                    </ProductDetails>
                    {!isDigitizingFee && (
                        <div className={styles.extraDetails}>
                            {rmaEligibleDate && rmaAvailable && (
                                <div className={styles.returnInfo}>
                                    {t('Return or replace items: Eligible through %1', rmaEligibleDate)}
                                </div>
                            )}
                            <BuyAgainAction
                                config={config}
                                orderItems={buyAgainItems}
                            />
                        </div>
                    )}
                </div>
                <div className={classes(styles.itemDetail, styles.itemLinks)}>
                    <ItemActions
                        config={config}
                        order={order}
                        orderItem={orderItem}
                        showTrackButton={showTrackButton}
                        showSupportButton={showSupportButton}
                        showReturnButton={showReturnButton}
                        showReviewButton={showReviewButton}
                        filterTracksByShipmentUid={filterTracksByShipmentUid}
                    />
                </div>
            </div>
        </div>
    );
};
