import React, { useState } from 'react';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { ISalesLayoutConfig } from 'ui/page/sales-pages/layouts/sales-layout/sales-layout-state';
import {
    ICustomerOrder,
    IOrderCreditMemo,
    IOrderCreditMemoItem,
} from 'graphql/customer/customerOrdersInterfaces';
import { SalesEntityLayout } from 'ui/component/sales-components/sales-details-layout/sales-entity-layout';
import { getRefundOrderItem } from '../../../../util/get-order-items-data';
import { OrderItem } from '../../../../order/items/item';
import styles from '../../style.css';

export interface IRefundParams {
    config: ISalesLayoutConfig;
    order: ICustomerOrder;
    refund: IOrderCreditMemo;
}

export const Refund: React.FC<IRefundParams> = (
    {
        config,
        order,
        refund,
    },
) => {
    const [wrapperHasZIndex, setWrapperHasZIndex] = useState(false);
    const t = usePhraseTranslater();

    const {
        id,
        number,
        items: refundItems,
        printRefundUrl,
        total,
    } = refund;

    return (
        <SalesEntityLayout
            salesEntityUId={id}
            salesEntityLabel={t('Refund')}
            salesEntityNumber={number}
            salesEntityPrintUrl={printRefundUrl}
            total={total}
            className={(wrapperHasZIndex ? styles.orderEntityZIndex : '')}
        >
            <>
                {refundItems.map(
                    (refundItem: IOrderCreditMemoItem) => {
                        const orderItem = getRefundOrderItem(refundItem);
                        if (!orderItem) {
                            return null;
                        }

                        return (
                            <OrderItem
                                className={styles.orderEntityItem}
                                config={config}
                                order={order}
                                orderItem={orderItem}
                                showItemStatus={false}
                                showPriceTotal
                                priceTotalLabel={t('Subtotal:')}
                                setWrapperHasZIndex={setWrapperHasZIndex}
                            />
                        );
                    },
                )}
            </>
        </SalesEntityLayout>
    );
};
