import React, { useState, useEffect } from 'react';
import { useReactiveVar } from '@apollo/client';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { Navigate, useNavigate } from '@silkpwa/module/router/use-navigate';
import { ICustomerOrder } from 'graphql/customer/customerOrdersInterfaces';
import { navigateToUrl } from 'ui/util/navigate-to-url';
import { OrderInvoices } from 'ui/component/orders-list/order-details';
import {
    salesLayoutConfigVar,
    currentOrderVar,
    ISalesLayoutConfig,
} from '../../layouts/sales-layout/sales-layout-state';
import { salesGuestValidOrderResultVar } from '../../layouts/guest-layout/guest-layout-state';
import { SalesLayout } from '../../layouts/sales-layout';
import { OrderDetailsPageContent } from '../order-details-page-content';

const OrderInvoicesPage: React.FC = () => {
    const [isLoaded, setIsLoaded] = useState<boolean>(false);

    const config: ISalesLayoutConfig = useReactiveVar(salesLayoutConfigVar);
    const currentOrder: ICustomerOrder|undefined = useReactiveVar(currentOrderVar);
    const salesGuestValidOrderResult = useReactiveVar(salesGuestValidOrderResultVar);

    const t = usePhraseTranslater();
    const navigate: Navigate = useNavigate();

    const pageTitle = t('Your Invoices');

    const guestOrderInvoicePageComponent: JSX.Element = isLoaded ? (
        <OrderDetailsPageContent pageTitle={pageTitle}>
            <OrderInvoices config={config} order={currentOrder} />
        </OrderDetailsPageContent>
    ) : (<></>);

    useEffect(() => {
        if (!salesGuestValidOrderResult) {
            return;
        }

        const { result, redirectUrl } = salesGuestValidOrderResult;
        const isRedirect = !result && !!redirectUrl.length;

        if (isRedirect) {
            navigateToUrl({
                navigate,
                url: redirectUrl,
            });
            return;
        }

        setIsLoaded(true);
    }, [salesGuestValidOrderResult]);

    return (
        <SalesLayout
            pageKey="guest-order-invoices"
            pageTitle={pageTitle}
            pageContent={guestOrderInvoicePageComponent}
            loadCountries
            loadWidgets
            isGuestPage
            loadInvoices
            splitOrderItems={false}
        />
    );
};

export { OrderInvoicesPage as default };
