import React from 'react';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { ICustomerOrder } from 'graphql/customer/customerOrdersInterfaces';
import { ISalesLayoutConfig } from 'ui/page/sales-pages/layouts/sales-layout/sales-layout-state';
import { OrderEntity } from './order-entity';
import { Invoices } from './order-entity/invoices';

interface IOrderInvoicesProps {
    config: ISalesLayoutConfig;
    order?: ICustomerOrder|undefined;
}

export const OrderInvoices: React.FC<IOrderInvoicesProps> = (
    {
        config,
        order,
    },
) => {
    const t = usePhraseTranslater();

    if (!order) {
        return null;
    }

    const { invoices, order_urls: orderUrls } = order;
    const { print_all_invoices_url: printAllInvoicesUrl } = orderUrls;

    return (
        <OrderEntity
            order={order}
            layoutPage="invoices"
            entityLabel={t('Invoices')}
            entityPrintAllUrl={printAllInvoicesUrl}
        >
            <Invoices config={config} order={order} invoices={invoices} />
        </OrderEntity>
    );
};
