import React from 'react';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { classes } from '@silkpwa/module/util/classes';
import { ITracking } from 'graphql/customer/customerOrdersInterfaces';
import { Link } from 'ui/component/custom/link';
import styles from './style.css';

export interface ITrackingParams {
    tracks: ITracking[];
    className?: string;
}

export const Tracking: React.FC<ITrackingParams> = (
    {
        tracks,
        className,
    },
) => {
    const t = usePhraseTranslater();
    if (!tracks || !tracks.length) {
        return null;
    }

    const { length } = tracks;

    return (
        <div className={classes(styles.shipmentTracksWrapper, (className || ''))}>
            <div className={styles.shipmentTracksLabel}>
                {(length > 1 ? t('Tracking Numbers:') : t('Tracking Number:'))}
            </div>
            <div className={styles.shipmentTracks}>
                {tracks.map(
                    (track: ITracking, index: number) => {
                        const {
                            number,
                            trackingUrl,
                        } = track;
                        return (
                            <div className={styles.shipmentTrack}>
                                <Link
                                    label={number}
                                    href={trackingUrl}
                                    target="_blank"
                                    opacityOnHover
                                />
                                {(index + 1) < length && (
                                    <span className={styles.separator}>,</span>
                                )}
                            </div>
                        );
                    },
                )}
            </div>
        </div>
    );
};
