import React from 'react';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { classes } from '@silkpwa/module/util/classes';
import { ICustomerOrder } from 'graphql/customer/customerOrdersInterfaces';
import { OrderAddress } from './order-address';
import { SalesSummary } from '../sales-summary';
import { PaymentMethods } from './payment-methods';
import styles from './style.css';

interface IOrderInformationProps {
    order: ICustomerOrder;
}

export const OrderInformation: React.FC<IOrderInformationProps> = ({ order }) => {
    const t = usePhraseTranslater();

    const {
        shipping_address: shippingAddress,
        payment_methods: paymentMethods,
        total,
    } = order;

    return (
        <div className={styles.orderInformationWrapper}>
            <div className={classes(styles.orderInfoRow, styles.titlesRow)}>
                <div className={classes(styles.innerInfoRow, styles.titles)}>
                    <div className={classes(styles.title, styles.shippingAddress)}>
                        <span>{t('Shipping Address')}</span>
                    </div>
                    <div className={classes(styles.title, styles.paymentMethod)}>
                        <span>{t('Payment Method')}</span>
                    </div>
                    <div className={classes(styles.title, styles.orderSummary)}>
                        <span>{t('Order Summary')}</span>
                    </div>
                </div>
            </div>
            <div className={classes(styles.orderInfoRow, styles.contentRow)}>
                <div className={classes(styles.innerInfoRow, styles.content)}>
                    <div className={classes(styles.contentRow, styles.shippingAddress)}>
                        <OrderAddress orderAddress={shippingAddress} />
                    </div>
                    <PaymentMethods
                        paymentMethods={paymentMethods}
                        className={classes(styles.contentRow, styles.paymentMethod)}
                    />
                    <div className={classes(styles.contentRow, styles.orderSummary)}>
                        <SalesSummary salesTotal={total} />
                    </div>
                </div>
            </div>
        </div>
    );
};
