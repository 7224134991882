import React, { useState } from 'react';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { ISalesLayoutConfig } from 'ui/page/sales-pages/layouts/sales-layout/sales-layout-state';
import {
    ICustomerOrder,
    IOrderShipment,
    IOrderShipmentItem,
} from 'graphql/customer/customerOrdersInterfaces';
import { SalesEntityLayout } from 'ui/component/sales-components/sales-details-layout/sales-entity-layout';
import { getShipmentOrderItem } from '../../../../util/get-order-items-data';
import { OrderItem } from '../../../../order/items/item';
import { Tracking } from './tracking';
import styles from '../../style.css';

export interface IShipmentParams {
    config: ISalesLayoutConfig;
    order: ICustomerOrder;
    shipment: IOrderShipment;
}

export const Shipment: React.FC<IShipmentParams> = (
    {
        config,
        order,
        shipment,
    },
) => {
    const [wrapperHasZIndex, setWrapperHasZIndex] = useState(false);
    const t = usePhraseTranslater();

    const {
        id: shipmentId,
        number,
        items: shipmentItems,
        tracking: tracks,
        printShipmentUrl,
    } = shipment;

    const { length } = tracks;
    const tracking = length > 0 ? (<Tracking tracks={tracks} />) : undefined;

    return (
        <>
            <SalesEntityLayout
                salesEntityUId={shipmentId}
                salesEntityLabel={t('Shipment')}
                salesEntityNumber={number}
                salesEntityPrintUrl={printShipmentUrl}
                className={(wrapperHasZIndex ? styles.orderEntityZIndex : '')}
                afterHeadLineContent={tracking}
            >
                {shipmentItems.map(
                    (shipmentItem: IOrderShipmentItem) => {
                        const orderItem = getShipmentOrderItem(shipmentItem);
                        if (!orderItem) {
                            return null;
                        }

                        return (
                            <OrderItem
                                className={styles.orderEntityItem}
                                config={config}
                                order={order}
                                orderItem={orderItem}
                                showItemStatus={false}
                                showPriceTotal
                                priceTotalLabel={t('Subtotal:')}
                                setWrapperHasZIndex={setWrapperHasZIndex}
                                filterTracksByShipmentUid={shipmentId}
                            />
                        );
                    },
                )}
            </SalesEntityLayout>
        </>
    );
};
