import React from 'react';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { classes } from '@silkpwa/module/util/classes';
import { ICustomerOrder } from 'graphql/customer/customerOrdersInterfaces';
import { Link } from 'ui/component/custom/link';
import styles from './style.css';

interface IOrderHeadlineProps {
    order: ICustomerOrder;
    orderNumberAsLink?: boolean;
    layoutPage?: 'invoices'|'shipments'|'refunds'|'returns';
    shipmentsPage?: boolean;
    refundsPage?: boolean;
    returnsPage?: boolean;
}

export const OrderHeadline: React.FC<IOrderHeadlineProps> = (
    {
        order,
        orderNumberAsLink = false,
        layoutPage,
    },
) => {
    const t = usePhraseTranslater();

    const {
        number,
        order_date_formatted: orderedDate,
        order_urls: orderUrls,
        invoices,
        shipments,
        credit_memos: refunds,
        returns,
    } = order;
    const { total_count: returnsCount } = returns;
    const {
        details_url: detailsUrl,
        print_order_url: printUrl,
        invoice_url: invoiceUrl,
        shipment_url: shipmentUrl,
        refunds_url: refundsUrl,
        returns_url: returnsUrl,
    } = orderUrls;

    return (
        <div className={styles.orderHeadlineWrapper}>
            <div className={classes(styles.headlineRow, styles.orderInfo)}>
                <div className={classes(styles.orderLine, styles.date)}>{t('Ordered %1', orderedDate)}</div>
                {orderNumberAsLink && (
                    <Link
                        label={t('Order #%1', number)}
                        href={detailsUrl}
                        opacityOnHover
                    />
                )}
                {!orderNumberAsLink && (
                    <div className={classes(styles.orderLine, styles.number)}>{t('Order #%1', number)}</div>
                )}
            </div>
            <div className={classes(styles.headlineRow, styles.orderInfo)}>
                <Link
                    label={t('Print Order')}
                    linkClassName={classes(styles.orderLine, styles.printOrder)}
                    href={printUrl}
                    target="_blank"
                    opacityOnHover
                />
            </div>
            <div className={classes(styles.headlineRow, styles.viewUrls)}>
                {invoices.length > 0 && (
                    <Link
                        label={(invoices.length > 1 ? t('View / Print Invoices') : t('View / Print Invoice'))}
                        linkClassName={(layoutPage === 'invoices' ? styles.active : '')}
                        href={invoiceUrl}
                        opacityOnHover
                    />
                )}
                {shipments.length > 0 && (
                    <Link
                        label={(shipments.length > 1 ? t('View / Print Shipments') : t('View / Print Shipment'))}
                        linkClassName={(layoutPage === 'shipments' ? styles.active : '')}
                        href={shipmentUrl}
                        opacityOnHover
                    />
                )}
                {refunds.length > 0 && (
                    <Link
                        label={(refunds.length > 1 ? t('View Refunds') : t('View Refund'))}
                        linkClassName={(layoutPage === 'refunds' ? styles.active : '')}
                        href={refundsUrl}
                        opacityOnHover
                    />
                )}
                {returnsCount > 0 && (
                    <Link
                        label={(returnsCount > 1 ? t('View Returns') : t('View Return'))}
                        linkClassName={(layoutPage === 'returns' ? styles.active : '')}
                        href={returnsUrl}
                        opacityOnHover
                    />
                )}
            </div>
        </div>
    );
};
