import React from 'react';
import { classes } from '@silkpwa/module/util/classes';
import { ISalesLayoutConfig } from 'ui/page/sales-pages/layouts/sales-layout/sales-layout-state';
import { ICustomerOrder, IOrderInvoice } from 'graphql/customer/customerOrdersInterfaces';
import { Invoice } from './invoice';
import styles from '../style.css';

export interface IInvoicesParams {
    config: ISalesLayoutConfig;
    order: ICustomerOrder;
    invoices: IOrderInvoice[];
}

export const Invoices: React.FC<IInvoicesParams> = (
    {
        config,
        order,
        invoices,
    },
) => {
    if (!invoices || !invoices.length) {
        return null;
    }

    return (
        <div className={classes(styles.orderEntityList)}>
            {invoices.map(
                (invoice: IOrderInvoice) => (
                    <Invoice config={config} order={order} invoice={invoice} />
                ),
            )}
        </div>
    );
};
