import React, { useState } from 'react';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { ISalesLayoutConfig } from 'ui/page/sales-pages/layouts/sales-layout/sales-layout-state';
import {
    ICustomerOrder,
    IOrderInvoice,
    IOrderInvoiceItem,
} from 'graphql/customer/customerOrdersInterfaces';
import { SalesEntityLayout } from 'ui/component/sales-components/sales-details-layout/sales-entity-layout';
import { getInvoiceOrderItem } from '../../../../util/get-order-items-data';
import { OrderItem } from '../../../../order/items/item';
import styles from '../../style.css';

export interface IInvoiceParams {
    config: ISalesLayoutConfig;
    order: ICustomerOrder;
    invoice: IOrderInvoice;
}

export const Invoice: React.FC<IInvoiceParams> = (
    {
        config,
        order,
        invoice,
    },
) => {
    const [wrapperHasZIndex, setWrapperHasZIndex] = useState(false);
    const t = usePhraseTranslater();

    const {
        id,
        number,
        items: invoiceItems,
        printInvoiceUrl,
        total,
    } = invoice;

    return (
        <SalesEntityLayout
            salesEntityUId={id}
            salesEntityLabel={t('Invoice')}
            salesEntityNumber={number}
            salesEntityPrintUrl={printInvoiceUrl}
            total={total}
            className={(wrapperHasZIndex ? styles.orderEntityZIndex : '')}
        >
            <>
                {invoiceItems.map(
                    (invoiceItem: IOrderInvoiceItem) => {
                        const orderItem = getInvoiceOrderItem(invoiceItem);
                        if (!orderItem) {
                            return null;
                        }

                        return (
                            <OrderItem
                                className={styles.orderEntityItem}
                                config={config}
                                order={order}
                                orderItem={orderItem}
                                showItemStatus={false}
                                showPriceTotal
                                priceTotalLabel={t('Subtotal:')}
                                setWrapperHasZIndex={setWrapperHasZIndex}
                            />
                        );
                    },
                )}
            </>
        </SalesEntityLayout>
    );
};
